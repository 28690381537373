import React from 'react';
import Loader from '../components/elements/loading/Loader';
import Illustration from '../assets/logo/BfBagOnly.svg';

const LaunchScreen = () => {
  return (
    <div className="h-screen w-full flex items-center justify-center text-gray-700 text-sm">
      <div>
        <div className="relative">
          <div className="absolute z-30" style={{ left: "126px", top: "95px" }}> <Loader size="25" color="#ffffff"/> </div>
          <img
            className="relative z-20 w-56 mx-auto"
            alt="Breaking Food"
            title="Breaking Food"
            src={Illustration}
          />
          {/* <div className="text-center">
            chargement...
          </div> */}
        </div>

      </div>
    </div>
  );
}

export default LaunchScreen;




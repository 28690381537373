import React, { lazy, Suspense, useState, useEffect } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
/* ANALYSER => https://www.npmjs.com/package/framer-motion */

import { Route, Switch, useLocation } from 'react-router-dom';
import LaunchScreen from './utils/LaunchScreen';

/* component page */
const HomePage = lazy (() => import('./pages/homePage/HomePage'));
const ConceptPage = lazy(() => import('./pages/conceptPage/ConceptPage'));
const FishUpPage = lazy(() => import('./pages/conceptPage/fishUp/FishUpPage'));
const OuiChefPage = lazy(() => import('./pages/conceptPage/ouiChef/OuiChefPage'));
const PereCastorPage = lazy(() => import('./pages/conceptPage/pereCastor/PereCastorPage'));
const MaCheBello = lazy(() => import('./pages/conceptPage/machebello/MaCheBelloPage'));
const ChickenDate = lazy(() => import('./pages/conceptPage/chickenDate/ChickenDatePage'));
const FindUsPage = lazy(() => import('./pages/findUsPage/FindUsPage'));
const ContactPage = lazy(() => import('./pages/contactPage/ContactPage'));
const SignUpMailingListe = lazy(() => import('./pages/SignUpMailingListe'));
const ErrorPage = lazy(() => import('./pages/errorPage/ErrorPage'));
const DeliveryPage = lazy(() => import('./pages/deliveryPage/DeliveryPage'));

const Routes = () => {

  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.3
  };

  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 0);
      return () => {
        clearTimeout(timeout);
      };
    }, []);

    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}>
              <LaunchScreen />
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  }; 

  return (
    <>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch location={location} key={location.pathname}> {/* Switch permet de définir si une route n'existe pas, en "checkant" les routes dans le composant // Use Location est urilisé dans l'annimation de changement de route */}

            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}>

              <Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/nos-concepts" component={ConceptPage} />
                <Route path="/nos-concepts/fish-up" component={FishUpPage} />
                <Route path="/nos-concepts/pere-castor" component={PereCastorPage} />
                <Route path="/nos-concepts/oui-chef" component={OuiChefPage} />
                <Route path="/nos-concepts/ma-che-bello" component={MaCheBello} />
                <Route path="/nos-concepts/chicken-date" component={ChickenDate} />
                <Route path="/livraison" component={DeliveryPage} />
                <Route path="/reseau-breaking-food" component={FindUsPage} />
                <Route path="/contact-breaking-food" component={ContactPage} />
                <Route path="/signUpMailingListeBreakingFood" component={SignUpMailingListe} />
                <Route component={ErrorPage} /> 
               
              </Switch>

            </motion.div>

          </Switch>
        </Suspense>
      </AnimatePresence>
    </>
  );
}

export default Routes;

import React, {useState} from 'react';
import ScrollToTop from './components/utils/ScrollToTop';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import { SpeakerphoneIcon, XIcon } from '@heroicons/react/outline';
import { isMobile } from 'react-device-detect';

function App() {

  const [displayBanner, setdisplayBanner] = useState(true)

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />

        {/* MESSAGE TOP BANNER QR CODE MOBILE DETECT*/}
        {displayBanner && isMobile ?
            <div className="bg-orange-500">
              <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between flex-wrap">
                  <div className="w-0 flex-1 flex items-center">
                    <span className="flex p-2 mr-4 rounded-lg bg-white">
                      <SpeakerphoneIcon className="h-6 w-6 text-orange-500" aria-hidden="true" />
                    </span>
                    <p className="ml-3 font-medium text-white text-sm truncate">
                      <span>Télécharger notre application.</span>
                    </p>
                  </div>
                  <div className="order-3 mt-2 flex items-center justify-center space-x-3 w-full sm:order-2 sm:mt-0 sm:w-auto">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.dishop.breakingfood"
                      className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium bg-white  hover:bg-gray-300 animate"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className='w-5 h-5'><path fill="none" d="M0 0h24v24H0z"/><path d="M4 1.734a1 1 0 0 1 .501.135l16.004 9.266a1 1 0 0 1 0 1.73L4.501 22.131A1 1 0 0 1 3 21.266V2.734a1 1 0 0 1 1-1zm8.292 11.68l-4.498 4.498 5.699-3.299-1.2-1.2zM5 6.118v11.76l5.88-5.88-5.88-5.88zm10.284 4.302L13.706 12l1.578 1.577L18.008 12l-2.725-1.579zm-7.49-4.336l4.5 4.5 1.199-1.2-5.699-3.3z" fill="rgba(247,98,8,1)"/></svg>
                    </a>
                    <a
                      href="https://apps.apple.com/fr/app/breaking-food/id1592296084"
                      className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium bg-white  hover:bg-gray-300 animate"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className='w-5 h-5'><path fill="none" d="M0 0h24v24H0z"/><path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zM8.823 15.343l-.79 1.37a.823.823 0 1 1-1.428-.822l.589-1.016c.66-.206 1.201-.048 1.629.468zM13.21 8.66l2.423 4.194h2.141a.82.82 0 0 1 .823.822.82.82 0 0 1-.823.823h-1.19l.803 1.391a.824.824 0 0 1-1.427.823l-3.04-5.266c-.69-1.19-.198-2.383.29-2.787zm.278-3.044c.395.226.528.73.302 1.125l-3.528 6.109h2.553c.826 0 1.29.972.931 1.645h-7.48a.82.82 0 0 1-.822-.823.82.82 0 0 1 .822-.822h2.097l2.685-4.653-.838-1.456a.824.824 0 0 1 1.427-.823l.359.633.367-.633a.823.823 0 0 1 1.125-.302z" fill="rgba(247,98,8,1)"/></svg>
                    </a>
                  </div>
                  <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                    <button
                      onClick={(e) => setdisplayBanner(false)}
                      type="button"
                      className="-mr-1 flex p-2 rounded-md text-white hover:bg-white hover:text-orange-500 bg-opacity-25 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2 animate"
                    >
                      <span className="sr-only">Supprimer</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
        : null }
        <Routes />
      </BrowserRouter>
    </>
  );
}

export default App;

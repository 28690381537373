import React from 'react';
import { MoonLoader } from 'react-spinners';

const Loader = (props) => {

  let { color, size } = props;

  if (color === undefined ) {
    color = "#d94c15"
  }
  if (size === undefined) {
    size = "25"
  }
  return (
    <div className="flex items-center w-full h-full">
      <div className="mx-auto my-auto">
        <MoonLoader color={color} size={size+"px"} />
      </div>
    </div>
  );
}

export default Loader;
